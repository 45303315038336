import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const OpenSource = () => (
  <Layout>
    <SEO title="OpenSource" />

    <h1>Open-Source</h1>
  </Layout>
)

export default OpenSource
